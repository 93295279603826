import React from "react";
import "./ContuctHero.css";

function ContuctHero() {
  return (
    <>
      <section className="contuct_hero">
        <div className="contuct_text">
          <h1>Contact Us</h1>
        </div>
      </section>
    </>
  );
}

export default ContuctHero;
