import img1 from "./Logos/abgmaxx.png";
import img2 from "./Logos/Bahamas-Post-Office.png";
import img3 from "./Logos/bahamas.png";
import img4 from "./Logos/BF.png";
import img5 from "./Logos/Boibangla.png";
import img6 from "./Logos/Butikkjakt.png";
import img7 from "./Logos/creative-studio.png";
import img8 from "./Logos/E&F.png";
import img9 from "./Logos/excuse-my-beauty.png";
import img10 from "./Logos/M4A.png";
import img11 from "./Logos/newimage.png";
import img12 from "./Logos/Parachute.png";
import img13 from "./Logos/precigion.png";
import img14 from "./Logos/StudioX.png";
import img15 from "./Logos/The-govt-of-bahamas.png";

const Brandslider = [
  {
    id: 1,
    img: img1,
  },
  {
    id: 2,
    img: img2,
  },
  {
    id: 3,
    img: img3,
  },
  {
    id: 4,
    img: img4,
  },
  {
    id: 5,
    img: img5,
  },
  {
    id: 6,
    img: img6,
  },
  {
    id: 7,
    img: img7,
  },
  {
    id: 8,
    img: img8,
  },
  {
    id: 9,
    img: img9,
  },
  {
    id: 10,
    img: img10,
  },
  {
    id: 11,
    img: img11,
  },
  {
    id: 12,
    img: img12,
  },
  {
    id: 13,
    img: img13,
  },
  {
    id: 14,
    img: img14,
  },
  {
    id: 15,
    img: img15,
  },
];

export default Brandslider;
