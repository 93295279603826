import React from "react";
import Menu from "../Components/Header/Menu";

function Other() {
  return (
    <>
      <Menu />
    </>
  );
}

export default Other;
